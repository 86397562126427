<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="titlePage"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          :disabled="!orderInfo"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
          :disabled="!orderInfo"
          @click="updateButton"
          ref="submit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Отмена"
          leftIcon="bi-save"
          :disabled="!orderInfo"
          @click="goToView($route.params.order)"
        />
        <Modal
          v-show="editable"
          title="Удалить"
          button-text="Удалить"
          button-left-icon="bi-trash"
          :button-disabled="!orderInfo"
          size="sm"
          @submit="deleteButton"
        >
          <template v-slot:default>
            <p>Вы уверены?</p>
          </template>
          <template v-slot:actions>
            <UIButton type="submit" text="Удалить" />
          </template>
        </Modal>
      </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 400px"
      v-if="!orderInfo"
    >
      <i class="spinner-border" role="status"></i>
    </div>
    <div v-if="orderInfo">
      <Tabs :headers="['Основные данные', 'Работы']">
        <template v-slot:tab-0>
          <div class="row gap-3">
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">Наименование:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="orderInfo.BaseWork.Document.title"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Вид заказа:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="orderInfo.BaseWork.kind.id"
                      :options="workKinds"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Статус заказа:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="orderInfo.BaseWork.statusKind.id"
                      :options="workStatusKinds"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Приоритет:</dt>
                  <dl class="col">
                    <EditableSelect
                      v-model="orderInfo.BaseWork.priority.id"
                      :options="priorityKinds"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Ответственный:</dt>
                  <dl class="col">
                    <PersonCombobox
                      v-model="
                        orderInfo.BaseWork.Document.Issuer.DocumentPersonRole
                          .PersonRole.Person.id
                      "
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Объект:</dt>
                  <dl class="col">
                    <EditableSelectWithSearch
                      v-model="orderInfo.BaseWork.WorkLocation.Location.id"
                      :options="locationsList"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Тип графика:</dt>
                  <dl class="col">
                    <EditableSelect
                      :value="
                        lodash.get(
                          orderInfo,
                          'BaseWork.TimeSchedules[0].kind.id'
                        )
                      "
                      @input="
                        (e) => {
                          lodash.set(
                            orderInfo,
                            'BaseWork.TimeSchedules[0].kind.id',
                            e
                          );
                        }
                      "
                      :options="workTimeScheduleKind"
                      :editable="editable"
                    />
                  </dl>
                </div>

                <div class="d-inline-flex gap-5">
                  <div>
                    <dt class="col">Дата начала:</dt>
                    <dl class="col">
                      <!--TODO-->
                      {{ getRangePeriod.from }}
                    </dl>
                  </div>

                  <div>
                    <dt class="col">Дата окончания:</dt>
                    <dl class="col">
                      <!--TODO-->
                      {{ getRangePeriod.to }}
                    </dl>
                  </div>

                  <div>
                    <dt class="col">Расчетная стоимость:</dt>
                    <div class="col d-inline-flex align-items-center gap-2">
                      <span>{{ cost }} ₽</span>
                      <ModalDesigns
                        v-if="orderInfo.Designs.length > 0"
                        v-model="orderInfo.Designs"
                        :editable="editable"
                      />
                      <UIButton
                        v-if="editable"
                        text="Добавить расчет"
                        left-icon="bi-calculator-fill"
                        @click="goToNormCalc"
                      />
                    </div>
                  </div>

                  <div>
                    <dt class="col">Итоговая стоимость:</dt>
                    <div class="col d-inline-flex align-items-center gap-2">
                      <span>{{ orderInfo.totalCost }} ₽</span>
                    </div>
                  </div>
                </div>
              </div>
            </dl>
            <div class="col">
              <Map :assets="locationAssets" />
            </div>
            <div class="d-flex gap-5">
              <div>
                <dt class="col">Номер заказа:</dt>
                <dl class="col">
                  {{ orderInfo.workOrderNumber }}
                </dl>
              </div>
              <div>
                <dt class="col">Дата заявки:</dt>
                <dl class="col">
                  {{ orderInfo.BaseWork.Document.createdDateTime }}
                </dl>
              </div>
              <div>
                <dt class="col">Дата изменения:</dt>
                <dl class="col">
                  {{ orderInfo.BaseWork.Document.lastModifiedDateTime }}
                </dl>
              </div>
              <div>
                <dt class="col">Назначил:</dt>
                <dl class="col">
                  {{ authorName }}
                </dl>
              </div>
              <div>
                <dt class="col">Изменил:</dt>
                <dl class="col">
                  {{ editorName }}
                </dl>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:tab-1>
          <WorkTaskList :value="orderInfo.WorkTasks" :editable="editable" />
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import PersonCombobox from "@/views/Overview/order/components/personCombobox";
import ModalDesigns from "@/views/Overview/order/components/modalDesigns";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import EditableSelectWithSearch from "@/components/UI/EditableSelectWithSearch";
import Modal from "@/components/UI/Modal";
import Map from "@/components/Map.vue";
import Tabs from "@/components/UI/Tabs";
import WorkTaskList from "@/views/Overview/order/components/workTasks.vue";

import {
  API_work_delete,
  API_work_update,
  API_work_view,
} from "@/services/work";
import { API_getKindByModelName } from "@/services/references/kind";
import { getPersonName } from "@/plugins/getName";
import { API_AssetContainers, API_getAssetContainer, API_PriorityEnum} from "@/services/api";

export default {
  name: "OrderViewEdit",
  components: {
    UIButton,
    PersonCombobox,
    ModalDesigns,
    EditableInput,
    EditableSelect,
    EditableSelectWithSearch,
    Map,
    Modal,
    Tabs,
    WorkTaskList,
  },
  data() {
    return {
      /**
       * @type ?WorkServer
       */
      orderInfo: null,
      testTest: "test",
      workKinds: null,
      workStatusKinds: null,
      workTimeScheduleKind: [],
      priorityKinds: null,
      assetContainers: [],
      locations: [],
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      if (this.editable) {
        return "Редактирование заказа";
      }
      return "Просмотр заказа";
    },
    getRangePeriod: function () {
      if (!this.orderInfo.BaseWork) return undefined;
      const timeSchedules = this.orderInfo.BaseWork.TimeSchedules;
      let startDates = [];
      let endDates = [];
      timeSchedules.forEach((work) => {
        startDates.push(new Date(work.TimeSchedule.scheduleInterval.start));
        endDates.push(new Date(work.TimeSchedule.scheduleInterval.end));
      });
      return {
        from: new Date(Math.min.apply(null, startDates)).toLocaleDateString(),
        to: new Date(Math.max.apply(null, endDates)).toLocaleDateString(),
      };
    },
    cost: function () {
      if (!this.orderInfo.Designs) return undefined;
      let cost = "Расчет не утвержден";
      this.orderInfo.Designs.forEach((_i) => {
        if (_i.enproActive) cost = _i.costEstimate.value;
      });
      return cost;
    },
    authorName: function () {
      const person = this.lodash.get(
        this.orderInfo,
        "BaseWork.Document.Author.DocumentPersonRole.PersonRole.Person"
      );
      return getPersonName(person);
    },
    editorName: function () {
      const person = this.lodash.get(
        this.orderInfo,
        "BaseWork.Document.Editor.DocumentPersonRole.PersonRole.Person"
      );
      return getPersonName(person);
    },
    locationsList: function () {
      if (this.assetContainers.length > 0){
        return Array.from(this.assetContainers, (assetContainer) => ({
          id: assetContainer.locationId,
          value: assetContainer.name,
        }));
      }
      return null;
    },
    locationAssets: function () {
      if (this.orderInfo.BaseWork.WorkLocation.Location.id) {
        let orderLocationId = this.orderInfo.BaseWork.WorkLocation.Location.id;

        if (this.locations.length > 0) {
          let assetContainer = this.lodash.find(this.locations, {
            locationId: orderLocationId,
          });
          if (assetContainer){
            return assetContainer.assets ? assetContainer.assets : [];
          }
        }

        let assetContainer = this.lodash.find(this.assetContainers, {
          locationId: orderLocationId,
        });

        if (assetContainer){
          this.loadLocationAssets(assetContainer.id);
        }
      }

      return [];
    },
  },
  mounted() {
    API_work_view(parseInt(this.$route.params.order)).then((res) => {
      this.orderInfo = res;
    });
    API_getKindByModelName("WorkKind").then((res) => {
      this.workKinds = res;
    });
    API_getKindByModelName("WorkStatusKind").then((res) => {
      this.workStatusKinds = res;
    });
    API_getKindByModelName("WorkTimeScheduleKind").then((res) => {
      this.workTimeScheduleKind = res;
    });
    API_PriorityEnum().then((res) => {
      this.priorityKinds = res;
    });
    API_AssetContainers().then((res) => {
      this.assetContainers = res;
    });
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/overview/order/${this.$route.params.order}/edit`,
      });
    },
    goToView(order) {
      window.location.href = `/overview/order/${order}/`;
    },
    deleteButton: function () {
      API_work_delete(parseInt(this.$route.params.order)).then(() => {
        this.$router.push({
          name: "Orders today",
        });
      });
    },
    updateButton: function () {
      this.$refs.submit.setStatus(true);
      API_work_update(parseInt(this.$route.params.order), this.orderInfo)
        .then(() => {
          this.goToView(this.$route.params.order);
        })
        .finally(() => this.$refs.submit.setStatus(false));
    },
    goToNormCalc: async function () {
      await this.$router.push({
        name: "CreateDesign",
      });
    },
    loadLocationAssets: function (assetContainerId) {
      API_getAssetContainer(assetContainerId).then((assetContainer) => {
        let locationAssetContainer = this.lodash.find(this.locations, {
          id: assetContainer.id,
        });
        if (assetContainer.locationId && typeof locationAssetContainer === "undefined") {
          this.locations.splice(assetContainer.locationId, 1, assetContainer);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.map-holder {
  width: 500px;
  height: 300px;
  margin: auto;
}
.norm-calc {
  background-color: $main-1;
  color: white;
  border-radius: $border-radius;
}
</style>

<template>
  <div>
    <UIButton @click="modal.toggle()" left-icon="bi-view-list" />
    <!-- Modal -->
    <div class="modal fade" ref="designModal">
      <div
        class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-body p-0">
            <TableSlot v-bind="table" v-bind:data="parseValue">
              <template v-slot:default="{ row }">
                <td>
                  <UIButton
                    v-show="!row.enproActive"
                    text="Утвердить"
                    @click="approve(row)"
                    :disabled="!editable"
                  />
                  <span v-show="row.enproActive">Утвержденный</span>
                </td>
                <td>{{ row.WorkDocument.Document.title }}</td>
                <td>
                  {{
                    lodash.get(
                      lodash.find(designKinds, {
                        id: String(row.kind_id),
                      }),
                      "value",
                      "---"
                    )
                  }}
                </td>
                <td>{{ row.costEstimate.value }}</td>
                <td>{{ row.WorkDocument.Document.lastModifiedDateTime }}</td>
              </template>
              <template v-slot:actions="{ row }">
                <UIButton text="Подробнее" float @click="openDesign(row)" />
              </template>
            </TableSlot>
          </div>
          <div class="modal-footer">
            <UIButton text="Закрыть" data-bs-dismiss="modal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import TableSlot from "@/components/TableSlot";
import { Modal } from "bootstrap";
import modelGenerator from "@/plugins/modelGenerator";
import { updateDesign } from "@/services/work/schemas";
import { API_getKindByModelName } from "@/services/references/kind";
import { API_workDesign_set_active } from "@/services/work";

export default {
  name: "modalDesigns",
  components: {
    UIButton,
    TableSlot,
  },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    parseValue: function () {
      let res = [];
      this.value.forEach((item) => {
        res.push(modelGenerator(updateDesign, item));
      });
      return res;
    },
  },
  data() {
    return {
      modal: null,
      designKinds: [],
      table: {
        title: "Нормативные расчеты",
        columns: [
          {
            name: "Утверждённый вариант",
            path: "enproActive",
          },
          {
            name: "Наименование тех карты",
            path: "WorkDocument.Document.title",
          },
          {
            name: "Тип расчета",
            path: "kind_id",
          },
          {
            name: "Расчетная стоимость",
            path: "costEstimate.value",
          },
          {
            name: "Дата изменения",
            path: "WorkDocument.Document.lastModifiedDateTime",
          },
        ],
        actions: true,
      },
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs.designModal);
    API_getKindByModelName("DesignKind").then((res) => {
      this.designKinds = res;
    });
  },
  methods: {
    openDesign(e) {
      this.modal.hide();
      this.$router.push({
        name: "ViewDesign",
        params: {
          design: e.id,
        },
      });
    },
    approve(item) {
      this.value.forEach((d) => {
        d.enproActive = d.id === item.id;
      });
      item.enproActive = true;
      API_workDesign_set_active(item.id);
      window.location.href = `/overview/order/${this.$route.params.order}/`;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <TableSlot :columns="tableColumns" :data="value" actions>
      <template v-slot:default="{ row }">
        <td>
          {{ lodash.get(row, "BaseWork.Document.IdentifiedObject.name") }}
        </td>
        <td>
          {{
            lodash.get(
              row,
              "BaseWork.WorkLocation.Location.name",
              "не назначено"
            )
          }}
        </td>
        <td>
          {{
            new Date(
              lodash.get(
                row,
                "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start"
              )
            ).toLocaleDateString()
          }}
        </td>
        <td>
          {{
            new Date(
              lodash.get(
                row,
                "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end"
              )
            ).toLocaleDateString()
          }}
        </td>
        <td>
          {{ row.totalCost }}
        </td>
      </template>
      <template v-slot:actions="{ row }">
        <UIButton text="Подробнее" float @click="openWorkTask(row.id)" />
      </template>
    </TableSlot>
    <div class="d-flex justify-content-center py-2">
      <UIButton
        left-icon="bi-plus"
        text="Создать работу"
        @click="addWorkTask"
        :disabled="!editable"
      />
    </div>
  </div>
</template>

<script>
import TableSlot from "@/components/TableSlot";
import UIButton from "@/components/UI/Button";

export default {
  name: "WorkTaskList",
  components: { TableSlot, UIButton },
  props: {
    value: Array,
    editable: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      tableColumns: [
        {
          name: "Наименование работ",
          path: "BaseWork.Document.IdentifiedObject.name",
        },
        {
          name: "Место проведения работ",
          path: "BaseWork.WorkLocation.Location.name",
        },
        {
          name: "Дата начала",
          path: "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.start",
        },
        {
          name: "Дата окончания",
          path: "BaseWork.TimeSchedules[0].TimeSchedule.scheduleInterval.end",
        },
        {
          name: "Стоимость",
          path: "totalCost",
        },
      ],
    };
  },
  methods: {
    openWorkTask(workTaskId) {
      this.$router.push({
        name: "ViewWorkTask",
        params: {
          workTask: workTaskId,
        },
      });
    },
    addWorkTask() {
      this.$router.push({
        name: "CreateWorkTask",
      });
    },
  },
};
</script>

<style scoped></style>
